import Loader from "@/components/UI/LoadingComp"

export default {
  name: "transactions-table",
  components: {
    Loader
  },
  props: {
    dataTable: {
      type: Array,
    },
    current: {
      type: Number,
    },
    coinScales: {
      type: Object,
    },
    total: {
      type: Number,
    },
    pageSize: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      transactionTableHeader: [
        {
          title: this.$t("transactions.trxTime"),
          sortable: true,
          align: "center",
          key: "createTime",
          minWidth: 160,
          render: (h, params) => {
            return h(
              "div",
              { style: { direction: "ltr" } },
              this.$options.filters.dateFormat(params.row.createTime)
            );
          },
        },
        {
          title: this.$t("transactions.crypto"),
          align: "center",
          minWidth: 100,
          key: "symbol",
        },
        {
          title: this.$t("transactions.trxType"),
          align: "center",
          minWidth: 130,
          render: (h, params) => {
            return h("div", params.row.type);
          },
        },
        {
          title: this.$t("transactions.amount"),
          align: "center",
          minWidth: 120,
          render: (h, params) => {
            return h(
              "div",
              {
                attrs: {
                  title: params.row.amount,
                },
                style: {
                  direction: "ltr",
                },
              },
              this.numberWithCommas(
                params.row.amount,
                this.coinScales[params.row.symbol]
              )
            );
          },
        },
        {
          title: this.$t("transactions.fee"), //"handling fees payable"
          align: "center",
          minWidth: 120,
          render: (h, params) => {
            return h(
              "span",
              {
                attrs: {
                  title: params.row.fee,
                },
              },
              this.numberWithCommas(
                params.row.fee,
                this.coinScales[params.row.symbol]
              )
            );
          },
        },
        {
          title: this.$t("transactions.networkFee"),
          align: "center",
          minWidth: 120,
          render: (h, params) => {
            return h(
              "span",
              {
                attrs: {
                  title: params.row.discountFee,
                },
              },
              this.numberWithCommas(
                params.row.discountFee,
                this.coinScales[params.row.symbol]
              )
            );
          },
        },
        {
          title: this.$t("transactions.status"),
          align: "center",
          minWidth: 100,
          render: (h) => {
            return h("div", this.$t("transactions.completed"), "");
          },
        },
      ],
    };
  },
  computed: {},
  mounted() {},
  methods: {
    changePageNumber(pageIndex) {
      this.$emit("changePage", pageIndex);
    },
  },
};
